<template>
  <div v-if="data && !isLoading">
    <h3>{{reportName}}</h3>
    <b-button class="d-block mb-3" @click="handleChartToggle">{{ isChartShow ? 'Скрыть график' : 'Показать график' }}</b-button>
    <transition name="fade">
    <b-col v-show="isChartShow">
      <LineChart
          :chart-data="chartsSeries"
          :chart-options="chartOptions"
          :chart-group="chartGroup"
      />
    </b-col>
    </transition>
    <b-button class="mb-3" @click="handleTableToggle">{{ isTableShow ? 'Скрыть таблицу' : 'Показать таблицу' }}</b-button>
    <transition name="fade">
      <div v-show="isTableShow" class="publisher-statistic__charts">
        <div v-if="data && data.result">
          <table-lazy :error="!!error" :is-loading="isLoading">
            <statistics-table :statistics="data && data.result || []" :fields="data.field" :sums="data && data.sum || {}" />
          </table-lazy>
        </div>
        </div>
    </transition>
  </div>
  <div v-else class="px-2 min-height-360">
    <content-placeholders :rounded="true">
      <content-placeholders-img />
      <content-placeholders-heading />
    </content-placeholders>
  </div>
</template>

<script>
import LineChart from "@core/components/statistics-cards/LineChart.vue"
import {BButton, BCol, BRow} from "bootstrap-vue"
import {ref, watch} from "@vue/composition-api"
import {usePage} from "@/use/page"
import {fetchManagerStatisticsExperimental, controller} from "@/api/statistics"
import {statisticQueries} from "@/use/statisticQueries"
import {fetchPreset} from "@/api/lists"
import StatisticsTable from "@/components/StatisticsTable.vue"
import TableLazy from "@/components/TableLazy.vue"
import {makeChartsData, makeMultiChartsData} from "@/use/statisticHelpers"

export default {
  name: 'DashboardReport',
  components: {
    BButton,
    TableLazy,
    StatisticsTable,
    BCol,
    BRow,
    LineChart
  },
  props: {
    dateRange: {
      type: Object,
      required: true
    },
    visibilityState: {
      type: Object,
      required: true
    },
    report: {
      type: Number,
      required: true
    },
    group: {
      type: String,
      required: true
    }
  },

  setup(props, ctx) {

    const isTableShow = ref(true);
    const isChartShow = ref(false);
    const reportName = ref('');
    let controller = ref(new AbortController());
    const dataLoaded = ref(false);

    const initialVisibilityState = props.visibilityState[props.report] || null;

    if(initialVisibilityState) {
      if(initialVisibilityState.hasOwnProperty('chart')) isChartShow.value = initialVisibilityState.chart;
      if(initialVisibilityState.hasOwnProperty('table')) isTableShow.value = initialVisibilityState.table;
    }


    const {
      data,
      error,
      isLoading,
      fetchPageData
    } = usePage(fetchManagerStatisticsExperimental, null, ctx, 'notPreload')
    let {
      queryObj,
      fetchStatistic
    } = statisticQueries(fetchPageData, ctx)
    const handlePreset = (id) => {
      fetchPreset(id)
          .then((res) => {
            reportName.value = res.data.data.name;
            queryObj.filters = Object.assign({}, res.data.data.value.filters);
            queryObj.filters.start_date = props.dateRange.startDate.toLocaleDateString('en-CA');
            queryObj.filters.end_date = props.dateRange.endDate.toLocaleDateString('en-CA');
            queryObj.select = res.data.data.value.select;
            queryObj.group = [props.group];
            if(controller.value) controller.value.abort();
            controller.value = new AbortController();
            fetchStatistic(controller.value)
                .then((res) => {
                  dataLoaded.value = true;
                })
                .catch((err) => {
                  console.log(err);
                })
          })
    }

    const handleChangeDate = () => {
      dataLoaded.value = false;
      queryObj.filters.start_date = props.dateRange.startDate.toLocaleDateString('en-CA');
      queryObj.filters.end_date = props.dateRange.endDate.toLocaleDateString('en-CA');
      queryObj.group = [props.group];
      if(controller.value) controller.value.abort();
      controller.value = new AbortController();
      fetchStatistic(controller.value)
          .then((res) => {
            dataLoaded.value = true;
          })
    }

    watch(() => props.dateRange, () => {
      handleChangeDate()
    }, {deep: true})

    const chartsData = ref([])
    const chartsSeries = ref([])
    const chartGroup = ref([])
    const multiGroupDataLoaded = ref(false);

    const fieldsIgnoreList = ['group']
    const chartPalette = ['#ee1919', '#008ffb', '#00e396', '#fe807d', '#feb019', '#ff4560', '#775d6c', '#775dd0', '#ffe70c']
    const chartOptions = {
      chart: {
        height: 350,
        type: "line",
        stacked: false
      },
      toolbar: {
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        }
      },
      stroke: {
        width: 2
      },
      colors: chartPalette,
      plotOptions: {
        bar: {
          columnWidth: "20%"
        }
      },
      tooltip: {
        enabled: true,
      },
      legend: {
        horizontalAlign: "left",
        offsetX: 40
      },
      xaxis: {
        labels: {show: true},
        categories: [],
      },
      yaxis: []
    }


    watch(
        () => data,
        () => {

          if(data.value.field.length) {
            if(queryObj.group.length > 1) {
              multiGroupDataLoaded.value = true;
              makeMultiChartsData(chartGroup, chartOptions, chartsSeries, data);
            } else {
              multiGroupDataLoaded.value = false;
              makeChartsData(chartGroup, chartOptions, chartsSeries, data);
            }
          }
        },
        {deep: true}
    )

    handlePreset(props.report);

    return {
      chartsData,
      chartsSeries,
      chartGroup,
      chartOptions,
      data,
      error,
      dataLoaded,
      reportName,
      isLoading,
      isTableShow,
      isChartShow
    }
  },
  methods: {
    handleChartToggle() {
      this.isChartShow = !this.isChartShow;
      this.$emit('change', [this.report, 'chart', this.isChartShow]);
      window.dispatchEvent(new Event('resize'));
    },
    handleTableToggle() {
      this.isTableShow = !this.isTableShow;
      this.$emit('change', [this.report, 'table', this.isTableShow]);
    },
    handleChartShow(state = true) {
      this.isChartShow = state;
      this.$emit('change', [this.report, 'chart', this.isChartShow]);
      window.dispatchEvent(new Event('resize'));
    },
    handleTableShow(state = true) {
      this.isTableShow = state;
      this.$emit('change', [this.report, 'table', this.isTableShow]);
    }
  }
}

</script>

<style >
.apexcharts-toolbar {
  display: none;
}
</style>
